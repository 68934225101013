<template>
    <!-- nav -->
    <div class="nav">
         <ul class="list">
            <li v-for="(item,key) in list" :key="key" @click="subNav(key)" :class="item.item == '首頁' ? 'title' : ''">{{item.item}}
                <span v-if="item.liStyle = childrenIndex - 1 == key? true : false"></span>
            </li>
        </ul>
        <!-- 底部 -->
         <ul class="foot_bar">
            <li v-for="(item,key) in footBar" :key="key" @click="$router.push(`/privacy?index=${key}`)">{{item.item}}</li>
        </ul>
    </div>
</template>
<script>
export default {
    props:['childrenIndex'],
     data(){
        return{
            list:[
                {
                    id:1,
                    liStyle:false,
                    item:'首頁',
                },
                 {
                    id:2,
                    liStyle:false,
                    item:'企業介紹',
                },
                 {
                    id:3,
                    liStyle:false,
                    item:'產品介紹',
                },
                 {
                    id:4,
                    liStyle:false,
                    item:'最新資訊',
                },
            ],
            footBar:[
                {
                    id:1,
                    item:'聯繫我們',
                },
                 {
                    id:2,
                    item:'隱私政策 ',
                },
                 {
                    id:3,
                    item:'服務條款',
                },
            ],
            isKey:'',
        }
   },
   methods:{
       subNav(key){
        //    this.isKey = key
           this.$emit('swiperIndex',key + 1)
       }
   }
}
</script>
<style lang="scss" scoped>
.nav{
    width: 100%;
    position: absolute;
    bottom: 260px;
    left: 0;
    .list{
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: 306px;
        border-bottom: 2px solid #616169;
        padding: 0 383px;
        margin: 0 auto;
        font-size: 22px;
        li{
            width: 98px;
            height: 72px;
            line-height: 72px;
            position: relative;
            cursor: pointer;
            >span{
                width: 100%;
                height: 2px;
                background: #15a1ec;
                // padding: 0 50px;
                position: absolute;
                top: 72px;
                left: -6%;
            }
        }
        .title{
            letter-spacing: 27px;
            >span{
                left: -14%;
            }
        }
    }
    .foot_bar{
        width: 438px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 50px 0 0 0;
        font-size: 14px;
        list-style: none;
        li{
            width: 98px;
            height: 72px;
            line-height: 72px;
            cursor: pointer;
        }
        
    }

   
}
</style>
