<template>
  <div class="home">
      <!-- swiper -->
      <div class="swiper-container"  id="swiper">
          <div class="swiper-wrapper big_box">
              <div class="swiper-slide swipers swiper1">
                  <!-- content -->
                  <div class="content">
                     <div class="con_box">
                         <img src="@/assets/img/title.png" alt="">
                        <navList @swiperIndex = "getSwiper" :childrenIndex="1"></navList>
                     </div>
                  </div>
              </div>
              <div class="swiper-slide swipers swiper2">
                    <!-- content -->
                  <div class="content">
                     <div class="con_box">
                        <img src="@/assets/img/title.png" alt="">
                        <p>
                          <span>明日世界國際有限公司成立於2020年，由一群熱愛互聯網的有理想有熱情的年輕人組成，希望通過自<br/>己的智慧，為世界帶來改變。</span>
                          <span>公司的業務是研發應用軟體及遊戲，並面向全球發行，致力於將最好的產品帶給廣大的互聯網用戶。</span>
                        </p>
                        <navList @swiperIndex = "getSwiper" :childrenIndex="2"></navList>
                     </div>
                  </div>
              </div>
              <div class="swiper-slide swipers swiper3">
                  <div class="content">
                      <div class="swiper3_box">
                          <div class="left">
                             <!-- Swiper -->
                               <div class="swiper-container box">
                                  <div class="swiper-wrapper box_sm">
                                      <div class="swiper-slide sm_swiper"><img src="@/assets/img/ProjectM_2.jpg" alt=""></div>
                                      <div class="swiper-slide sm_swiper"><img src="@/assets/img/ProjectM_1.jpg" alt=""></div>
                                      <div class="swiper-slide sm_swiper"><img src="@/assets/img/ProjectM_3.jpg" alt=""></div>
                                      <div class="swiper-slide sm_swiper"><img src="@/assets/img/ProjectM_4.jpg" alt=""></div>
                                      <div class="swiper-slide sm_swiper"><img src="@/assets/img/ProjectM_5.jpg" alt=""></div>
                                 </div>
                              </div>  
                               <!-- 如果需要导航按钮 -->
                               <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                          </div>
                          <div class="right">
                            <img src="@/assets/img/title2.png" alt="">
                            <div class="game_desc">
                              <p>《我叫MT》是一部人氣極高的原創網路動畫劇集，是知名遊戲《魔獸世界》的同人作品。</p>
                              <p>2009年動畫片第一季上線後輕鬆出圈，火爆全網，連續更新6季。是當時的現象級的動漫作品。</p>
                              <p>《我叫MT：經典重現》是改編後的一款策略放置類遊戲，不僅高度還原<br/>了《我叫MT》的動畫設定，玩法做出了創新，更邀請了原版配音人員加<br/>盟，為遊戲助力。</p>
                            </div>
                          </div>
                      </div>
                      <navList @swiperIndex = "getSwiper" :childrenIndex="3"></navList>
                  </div>
              </div>
              <div class="swiper-slide swipers swiper4">
                  <div class="content">
                      <div class="new">
                          <h1>最新资讯</h1>
                          <div class="new_detail">
                              <img src="@/assets/img/MT1.png" alt="">
                              <div class="new_list">
                                  <ul>
                                    <li @click="$router.push(`/about?index=${0}`)">&nbsp; 回憶滿滿聚一堂 策略放置類手遊<br/>《我叫MT：經典再現》即將登場</li>
                                    <li @click="$router.push(`/about?index=${1}`)">《我叫MT：經典再現》背景介紹</li>
                                    <li @click="$router.push(`/about?index=${2}`)">《我叫MT：經典再現》角色介紹</li>
                                    <li @click="$router.push(`/about?index=${3}`)">《我叫MT：經典再現》系統介紹</li>
                                    <li @click="$router.push(`/about?index=${4}`)">《我叫MT：經典再現》特色介紹</li>
                                  </ul>
                                  <p> 遊戲將於近期和大家見面，歡迎大家持續關注！</p>
                              </div>
                          </div>
                      </div>
                      <navList @swiperIndex = "getSwiper" :childrenIndex="4"></navList>
                  </div>
              </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
      </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import navList from '../components/Nav.vue'
export default {
  components:{
    navList,
  },
   data(){
      return{
         liStyle:false,
        swiperIndex:0,
        mySwiper:'',
        swiper:'',
        // childrenIndex:'',
      }
   },
   mounted(){
        this.mySwiper = new Swiper ('#swiper', {
        direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        mousewheelControl : true, //鼠标滚动
        paginationClickable: true, //点击的
        // 如果需要分页器
        pagination : '.swiper-pagination',
        // onSlideChangeStart: function(swiper){
        //   alert(swiper.activeIndex);
        // }
      });

      this.Handscroll()
   },
   methods:{
     Handscroll(){
         var swiper = new Swiper ('.box', {
          slidesPerView: 2,
          spaceBetween: -180,
          paginationClickable: true,
          centeredSlides: true,
          // 如果需要前进后退按钮
          prevButton:'.swiper-button-prev',
          nextButton:'.swiper-button-next',
          loop: true,
          //设置自动轮播
          //  autoplay:3000,
        })
     },
     getSwiper(index){
        this.swiperIndex = index
        this.mySwiper.slideTo(index, 1000, false);//切换到第一个slide，速度为1秒
    }
   }
}
</script>
<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
    // swiper
    .swiper-container {
      width: 100%;
      height: 100%;
      .big_box{
         width: 100%;
         height: 100%;
         .swipers{
            position: relative;
            background:url('../assets/img/bg_1.png') no-repeat;
            background-size: 100% 100%;
           .content{
             width: 100%;
            //  height: 752px;
             height: 100%;
             position: absolute;
             top: 234px;
            // bottom: 0;
             left: 0;
             animation: slideUpMar .5s ease-in-out;
             .con_box{
               width: 100%;
               height: 100%;
               position: relative;
                >img{
                  display: block;
                  width: 980px;
                  height: 176px;
                  margin: 0 auto;
                }
                >p{
                    width: 1134px;
                    margin: 0 auto;
                    font-size: 24px;
                    padding-top: 120px;
                    >span:first-child{
                      padding-bottom: 70px;
                    }
                    span{
                      display: block;
                      line-height: 40px;
                    }
                }
             }
           }
          //   @keyframes slideUpMar {
          //     from {
          //       transform: translateY(100px);
          //     }
          //     to {
          //       transform: translateY(0px);
          //     }
          // }
           >img{
              width: 100%;
              height: 100%;
              // height: 1080px;
              // min-height: 100%;
              object-fit:cover;
              animation: .3s scaleIn ease-in-out;
           }
         }
          .swiper2,.swiper3,.swiper4{
              background:url('../assets/img/bg_2.png') no-repeat;
              background-size: 100% 100%;
            .content{
               top: 150px;
               >img{
                 width: 684px;
                 height: 125px;
               }
              .nav{
                bottom: 20%;
              }
            }
          }
          .swiper3{
            .content{
                .swiper3_box{
                  margin: 0 350px 0 165px;
                  display: flex;
                  justify-content: space-between;
                  .left{
                    width: 400px;
                    height: 450px;
                    position: relative;
                    .box{
                       position: relative;
                      width: 100%;
                      height: 100%;
                      .box_sm{
                        width: 100%;
                        height: 100%;
                        .swiper-slide{
                           width: 338px;
                           height: 450px;
                           text-align: center;
                            background: #CCCDCE;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: 300ms;
                            transform: scale(0.8);
                        }
                        .swiper-slide-active,
                        .swiper-slide-duplicate-active {
                            transform: scale(1);
                        }
                        .swiper-slide-active{
                            z-index: 999 !important;
                        }
                      }
                    }
                    .swiper-button-next{
                      position: absolute;
                      top: 50%;
                      right: -50px;
                      transform: translateY(-50%);
                    }
                    .swiper-button-prev{
                      position: absolute;
                      top: 50%;
                      left: -50px;
                      transform: translateY(-50%);
                    }
                    img{
                        width: 100%;
                        height: 100%;
                      }
                  }
                  .right{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-left: 100px;
                    font-size: 20px;
                    transform: translateY(-54px);
                    .game_desc{
                      >p{
                        width: 720px;
                        padding-top: 48px;
                        letter-spacing:2px;
                        line-height: 40px;
                      }
                    }
                  }
              }
            }
          }
          .swiper4{
            .content{
              .new{
                margin-left: 180px;
                >h1{
                  font-style: oblique;
                  padding-bottom: 44px;
                }
                .new_detail{
                  width: 1345px;
                  height: 396px;
                  display: flex;
                  align-items: center;
                  >img{
                    width: 855px;
                    height: 396px;
                  }
                  .new_list{
                    flex: 1;
                    height: 100%;
                    background-color: #14173d;
                    font-size: 20px;
                    padding-left: 15px;
                    >ul{
                      list-style: disc;
                      margin-left: 15px;
                      padding: 42px 0 0 30px;
                      >li{
                        cursor: pointer;
                        line-height: 45px;
                      }
                    }
                    >p{
                      font-size: 16px;
                      padding-left: 30px;
                      color: #fdf16b;
                      padding-top: 20px;
                    }
                  }
                }
              }
            }
          }
      }
    /deep/ .swiper-pagination-bullets{
      right: 170px !important;
    }
    /deep/ .swiper-pagination-bullet-active{
      background: #fff !important;
    }
    /deep/ .swiper-pagination-bullet-active::after{
      opacity: 1 !important;
      animation: scaleInDot .3s ease-in-out !important;
      background: transparent !important;
    }
    /deep/ .swiper-pagination-bullet{
      width: 8px;
      height: 8px;
      background: #fff;
      margin-top: 54px;
      opacity: 1;
      position: relative;
    }
    /deep/ .swiper-pagination-bullet::after{
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      background-color: currentColor;
      transform-origin: 50% 50%;
      width: 8px;
      height: 8px;
      border: 1px solid #fff;
      border-radius: 100%;
      transform: scale(3);
      opacity: 0;
    }
  }
}
</style>